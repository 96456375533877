import Vue from 'vue' // 引入vue
import VueI18n from 'vue-i18n' // 引入i18n模块
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui英文包
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'// element-ui中文包
import locale from 'element-ui/lib/locale' // 引入elementuiui语言包模块
import enLocale from './lang/en' // 本地英文包
import zhLocale from './lang/zh_CN' // 本地中文包

Vue.use(VueI18n) // vue使用i18n模块

// 引入本地
const messages = {
    en: {
        ...enLocale, // es6的拓展运算符，相当于解析出每个对象
        ...elementEnLocale
    },
    zh_CN: {
        ...zhLocale,
        ...elementZhLocale
    }
}

// 创建国际化实例
const index = new VueI18n({
    locale: localStorage.lang || 'zh_CN',
    messages // set locale messages。语言包
})

locale.i18n((key, value) => index.t(key, value))

export default index
