<template>
    <el-main>
        <div class="notfound">
            <h1>404</h1>
            <h2>您访问的页面没有找到！</h2>
            <el-button size="large" @click="goback" type="primary">
                返 回
            </el-button>
        </div>
    </el-main>
</template>

<script>
    export default {
        name: 'zk-not-found',
        methods: {
            goback() {
                this.$router.go(-1)
            }
        }
    }
</script>


<style scoped>
    h1 {
        font-size: 400%;
    }

    .notfound {
        padding: 40px 0;
        text-align: center;
    }
</style>
